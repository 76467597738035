import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs } from "@firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // New state to manage loading
  const usersRef = collection(firestore, "users");
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when sign in button is clicked

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false); // Set loading to false after sign-in is successful
    } catch (error) {
      console.error("Error during sign-in: ", error);
      setLoading(false); // Set loading to false after an error occurs
      toast.error("An error occurred during sign-in.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const { googleSignIn, user } = UserAuth();

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      await googleSignIn();
    } catch (error) {
      console.error("Google sign-in error: ", error);
      toast.error("Google sign-in failed. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.email) {
      const emailQuery = query(usersRef, where("email", "==", user.email));

      const fetchUserData = async () => {
        const emailQuerySnapshot = await getDocs(emailQuery);
        return emailQuerySnapshot.docs.map((doc) => doc.data());
      };

      fetchUserData().then((emailQueryData) => {
        const hasEthnicity = emailQueryData.some((doc) => doc.ethnicity);
        const hasGender = emailQueryData.some((doc) => doc.gender);
        const hasCountry = emailQueryData.some((doc) => doc.country);
        const hasPostcode = emailQueryData.some((doc) => doc.postcode);
        const hasDOB = emailQueryData.some((doc) => doc.date_birth);

        if (emailQueryData.length > 0 && hasEthnicity && hasGender && hasCountry && hasPostcode && hasDOB) {
          // User is complete, redirect to home page
          navigate("/active-questions");
        } else {
          // User isn't complete, redirect to complete profile page to provide additional information
          navigate("/complete-profile");
        }
      });
    }
  }, [user, navigate, usersRef]);

  return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="container mx-auto">
          <div className="flex items-center justify-center">
            <div className="w-3/4">
              <h1 className="text-black text-center text-3xl font-bold my-2">
                Choose a Login Method
              </h1>
            </div>
          </div>

          <div className="flex items-center justify-center flex-col md:flex-row mt-4">
            <div className="w-full max-w-xs">
              <form
                  className="bg-white rounded px-8 pt-6 pb-4 mb-4"
                  onSubmit={handleSignIn}
              >
                <div className="mb-4">
                  <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="email"
                  >
                    Email:
                  </label>
                  <input
                      className="shadow appearance-none w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="example@gmail.com"
                  />
                </div>
                <div className="mb-6">
                  <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                      className="shadow appearance-none w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="******************"
                  />
                </div>
                <div className="flex items-center justify-between mb-6">
                  <button
                      className="bg-black hover:bg-cyan-700 text-white py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={loading} // Disable the button while loading
                  >
                    {loading ? "Signing in..." : "Sign In"}
                  </button>
                  <a
                      className="inline-block align-baseline text-sm font-bold text-black hover:text-blue-800"
                      href="/reset-password"
                  >
                    Forgot Password?
                  </a>
                </div>
                <div className="flex flex-col items-center mb-4 mt-4 md:mt-0">
                  <button
                      type="button"
                      onClick={handleGoogleSignIn}
                      className="bg-black hover:bg-cyan-700 text-white py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
                      disabled={loading} // Disable the button while loading
                  >
                    {loading ? "Signing in..." : "Google"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </div>
  );
}

export default Signin;
