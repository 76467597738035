import React, {useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./components/Navbar";
import Protected from "./components/Protected";
import { AuthContextProvider } from "./context/AuthContext";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Signup from "./pages/SignUp";
import Footer from "./components/Footer";
import ModifyProfile from "./pages/ModifyProfile";
import QuestionPage from "./pages/Questions";
import QuestionsPage from "./pages/AllQuestions";
import ResetPasswordPage from "./pages/ResetPassword";
import QuestionDetail from "./components/QuestionDetail";
import SuggestQuestionPage from "./pages/SuggestQuestion";
import AdSenseSquareAd from "./components/adSenseSquare";
import Blog, {BlogPostList} from "./pages/Blog";

function App() {
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: false
            },
            "google_translate_element"
        );
    };

    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    return (
        <div>
            <AuthContextProvider>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/signin" element={<Signin/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                    <Route
                        path="/active-questions"
                        element={
                            <Protected>
                                {" "}
                                <QuestionPage/>{" "}
                            </Protected>
                        }
                    />
                    <Route path="/question/:questionId" element={<QuestionDetail/>}/>
                    <Route
                        path="/all-questions"
                        element={
                            <Protected>
                                {" "}
                                <QuestionsPage/>{" "}
                            </Protected>
                        }
                    />
                    <Route
                        path="/complete-profile"
                        element={
                            <Protected>
                                <ModifyProfile/>
                            </Protected>
                        }
                    />
                    <Route
                        path="/suggest-question"
                        element={
                            <Protected>
                                <SuggestQuestionPage/>
                            </Protected>
                        }
                    />
                    <Route path="/blog" element={<BlogPostList/>}/>
                    <Route path="/blog/:blogId" element={<Blog/>}/>
                </Routes>
                <AdSenseSquareAd/>
                <Footer/>
            </AuthContextProvider>
        </div>
    );
}

export default App;
