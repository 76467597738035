import React, {useState} from "react";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import { UserAuth } from "../context/AuthContext";
import {firestore} from "../firebase";
import {collection, addDoc} from "firebase/firestore";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement('#root'); // This is important for screen readers

const Hero = () => {
  const { user } = UserAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      toast.error("Feedback is empty. Please enter your feedback.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      setSubmitting(true);
      const feedbackRef = collection(firestore, "feedback");
      const docData = {
        userId: user ? user.uid : "anonymous",
        feedback: feedback,
        timestamp: new Date(),
      };

      await addDoc(feedbackRef, docData);
      setSubmitting(false);
      setFeedback("");
      closeModal();
      toast.success("Feedback submitted successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error("An error occurred while submitting the feedback. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitting(false);
    }
  };

  const renderButtons = () => {
    if (user) {
      return (
          <Link to="/active-questions">
            <button
                className="bg-black w-[200px] rounded-lg font-bold my-6 mx-auto py-3 text-white hover:bg-accent hover:text-accent-foreground">
              Active Questions
            </button>
          </Link>
      );
    } else {
      return (
          <div className="flex flex-col sm:flex-row justify-center">
            <Link to="/signin">
              <button
                  className="bg-black w-[200px] rounded-lg font-bold my-6 mx-2 sm:mx-4 py-3 text-white hover:bg-accent hover:text-accent-foreground">
                Sign In
              </button>
            </Link>
            <Link to="/signup">
              <button
                  className="bg-black w-[200px] rounded-lg font-bold my-6 mx-2 sm:mx-4 py-3 text-white hover:bg-accent hover:text-accent-foreground">
                Sign Up
              </button>
            </Link>
          </div>
      );
    }
  };

  return (
      <div className="text-black w-full mx-auto px-4 sm:px-6 lg:px-8 text-left">
        <div className="h-screen text-center flex flex-col justify-center w-full">
          <p className="text-4xl md:text-5xl py-6 mt-24 md:mt-2">
            Get answers to questions you are
          </p>
          <h1 className="text-3xl md:text-4xl py-2 md:py-6 font-bold">
            too afraid to ask
          </h1>
          {renderButtons()}

          <div className="text-left px-4 md:px-10 lg:px-20 xl:px-40 2xl:px-80">
            <h1 className="text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-bold py-4">
              How it works:
            </h1>
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl py-2">
              It’s simple! We ask the same question to every user worldwide, but
              we don’t share the results until at least 101 humans have answered!
            </p>
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl py-2">
              Once 101 humans have answered, you’ll be able to see how your answer
              compares based on your age, gender, ethnicity, and country. All the while
              your answers will remain anonymous.
            </p>
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl py-2">
              The following day we will post a new question, and the process will
              start again.
            </p>
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl py-2">
              Keep in mind that the old questions will always remain live for new
              users to answer, so make sure you come back and see your results
              over time!
            </p>
          </div>

          <div className="">
            <h1 className="text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-bold pt-8">
              Remember!
            </h1>
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl py-8">
              Honesty is the best policy! Tell the <b>truth</b> otherwise others won't get the same great
              insights into their lives.
            </p>
          </div>

          <button onClick={openModal}
                  className="bg-blue-500 w-[200px] rounded-lg font-bold my-6 mx-auto py-3 text-white hover:bg-blue-700">
            Send Feedback
          </button>

          <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Feedback Modal"
              className="modal"
              overlayClassName="modal-overlay"
          >
            <h2>Send Feedback</h2>
            <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                rows="5"
            ></textarea>
            <div className="flex justify-end mt-4">
              <button onClick={closeModal} className="bg-gray-500 text-white py-2 px-4 rounded mr-2">Cancel</button>
              <button onClick={handleFeedbackSubmit} className="bg-blue-500 text-white py-2 px-4 rounded"
                      disabled={submitting}>
                {submitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </Modal>
          <ToastContainer/>
        </div>
      </div>
  );
};

export default Hero;
