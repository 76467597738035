import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {UserAuth} from "../context/AuthContext";
import {firestore} from "../firebase";
import {Button} from "../components/ui/button";
import {collection, addDoc, getDocs, updateDoc, doc, getDoc} from "firebase/firestore";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SuggestQuestionPage = () => {
    const {user} = UserAuth();
    const [suggestedQuestion, setSuggestedQuestion] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleQuestionSubmit = async (event) => {
        event.preventDefault();

        if (!suggestedQuestion.trim()) {
            toast.error("Suggested question is empty. Please enter a question.", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        try {
            setSubmitting(true);
            const questionRef = collection(firestore, "suggestedQuestions");
            const docData = {
                userId: user.uid,
                question: suggestedQuestion,
                votes: 0,
                votedBy: [], // Initialize an array to track users who have voted
                timestamp: new Date(),
            };

            await addDoc(questionRef, docData);
            setSubmitting(false);
            setSubmitted(true);
            toast.success("Suggested question submitted successfully!", {
                position: toast.POSITION.TOP_CENTER,
            });
            fetchQuestions(); // Fetch updated list of questions
        } catch (error) {
            toast.error("An error occurred while submitting the question. Please try again.", {
                position: toast.POSITION.TOP_CENTER,
            });
            setSubmitting(false);
        }
    };

    const fetchQuestions = async () => {
        try {
            const questionRef = collection(firestore, "suggestedQuestions");
            const questionSnapshot = await getDocs(questionRef);
            const questionList = questionSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            // Sort questions by votes in descending order
            questionList.sort((a, b) => b.votes - a.votes);
            setQuestions(questionList);
            setLoading(false);
        } catch (error) {
            toast.error("An error occurred while fetching questions.", {
                position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
        }
    };

    const handleVote = async (questionId) => {
        try {
            const questionDoc = doc(firestore, "suggestedQuestions", questionId);
            const questionSnapshot = await getDoc(questionDoc); // Fetch the document snapshot

            if (!questionSnapshot.exists()) {
                toast.error("The question does not exist.", {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            const questionData = questionSnapshot.data();
            const votedBy = questionData.votedBy || []; // Default to an empty array if votedBy is not present

            if (votedBy.includes(user.uid)) {
                toast.error("You have already voted for this question.", {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }

            const updatedVotes = questionData.votes + 1;
            const updatedVotedBy = [...votedBy, user.uid];
            await updateDoc(questionDoc, {votes: updatedVotes, votedBy: updatedVotedBy});
            toast.success("Thank you for voting!", {
                position: toast.POSITION.TOP_CENTER,
            });
            await fetchQuestions(); // Refresh questions after voting
        } catch (error) {
            console.error("Error while voting: ", error);
            toast.error("An error occurred while voting.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };


    useEffect(() => {
        fetchQuestions();
    }, []);

    return (
        <div className="bg-white-900 text-black min-h-screen flex flex-col justify-center items-center p-10">
            <h2 className="text-3xl font-semibold mb-6">Suggest Question</h2>
            {submitted ? (
                <div className="text-green-400 mb-4 text-center">
                    Thank you, your question has been submitted!
                    <div className="flex justify-center my-8">
                        <Link to="/active-questions">
                            <Button className="mx-8 py-6 bg-black text-white" variant="outline">
                                Active Questions
                            </Button>
                        </Link>
                    </div>
                    <ToastContainer/>
                </div>
            ) : (
                <>
          <textarea
              cols="40"
              rows="5"
              placeholder="Enter your suggested question"
              value={suggestedQuestion}
              onChange={(e) => setSuggestedQuestion(e.target.value)}
              className="bg-gray-100 text-black p-2 rounded mb-4 border-gray"
          />
                    <br/>
                    <div className="flex justify-between my-2">
                        <Link to="/active-questions">
                            <Button className="bg-black text-white mx-4 py-4" variant="outline">
                                Active Questions
                            </Button>
                        </Link>
                        <Button
                            type="button"
                            disabled={submitting}
                            onClick={handleQuestionSubmit}
                            className="bg-black hover:bg-green-500 text-white font-bold py-2 px-4 rounded"
                        >
                            {submitting ? "Submitting..." : "Submit"}
                        </Button>
                        <ToastContainer/>
                    </div>
                </>
            )}

            <h2 className="text-3xl font-semibold mb-6 mt-10">Suggested Questions</h2>
            {loading ? (
                <p>Loading questions...</p>
            ) : (
                <div className="w-full max-w-3xl flex-1">
                    {questions.map((question) => (
                        <div
                            key={question.id}
                            className="flex flex-row items-center p-4 mb-4 border border-gray-300 rounded"
                        >
                            <div className="flex flex-row gap-4 items-center">
                                <button
                                    className="flex flex-col items-center px-4"
                                    onClick={() => handleVote(question.id)}
                                >
                                    <svg
                                        className="w-6 h-6 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="m7 14 5-5 5 5z"></path>
                                    </svg>
                                    <p className="text-gray-700">{question.votes}</p>
                                </button>
                                <div className="flex flex-col">
                                    <h6 className="text-lg font-semibold text-gray-800">{question.question}</h6>
                                    <p className="text-sm text-gray-500">
                                        All questions and answers are anonymous. Please be respectful.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SuggestQuestionPage;