import React, { useEffect, useState } from "react";
import { firestore } from "../firebase";
import {doc, collection, getDocs, query, where, updateDoc} from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function ModifyProfile() {
  const [userExists, setUserExists] = useState(false);
  const [userEthnicity, setUserEthnicity] = useState(false);
  const [userGender, setUserGender] = useState(false);
  const [userCountry, setUserCountry] = useState(false);
  const [userPostcode, setUserPostcode] = useState(false);
  const [userDOB, setUserDOB] = useState(false);
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [postcode, setPostcode] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [country, setCountry] = useState(""); // New state for country
  const [countries, setCountries] = useState([]); // New state for country list

  useEffect(() => {
    // Display a toast message when the component is mounted
    toast.warn("You haven't completed your information. Can you do it?", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000, // Adjust the duration as needed
    });
  }, []);

  useEffect(() => {
    // Fetch country data from the API when the component mounts
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryList = response.data.map((country) => country.name.common);
        setCountries(countryList.sort());
      } catch (error) {
        console.error("Error fetching countries: ", error);
        toast.error("Failed to load country list. Please try again.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.email) {
        const usersRef = collection(firestore, "users");
        const q = query(usersRef, where("email", "==", user.email));

        try {
          const querySnapshot = await getDocs(q);
          const userData = querySnapshot.docs.map((doc) => doc.data())[0];
          if (userData) {
            setUserExists(true);

            // Set user data to state variables
            setDob(userData.date_birth || "");
            setGender(userData.gender || "");
            setPostcode(userData.postcode || "");
            setEthnicity(userData.ethnicity || "");
            setCountry(userData.country || "");

            // Set flags for checking existing user data
            setUserEthnicity(typeof userData.ethnicity !== "undefined");
            setUserGender(typeof userData.gender !== "undefined");
            setUserCountry(typeof userData.country !== "undefined");
            setUserPostcode(typeof userData.postcode !== "undefined");
            setUserDOB(typeof userData.date_birth !== "undefined");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    fetchData();
  }, [user.email]);

  const handleModification = async (e) => {
    e.preventDefault();

    const isEthnicityValid = ethnicity !== "";
    const isGenderValid = gender !== "";
    const isCountryValid = country !== "";

    if (!isGenderValid) {
      toast.error("Please select a valid gender.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!isEthnicityValid) {
      toast.error("Please select a valid ethnicity.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!isCountryValid) {
      toast.error("Please select a country.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      // Store additional user information in Firebase database
      const userData = {
        user_id: user.uid,
        email: user.email,
        date_birth: dob,
        gender: gender,
        postcode: postcode,
        ethnicity: ethnicity,
        country: country,
      };

      //const ref = collection(firestore, "users");
      const usersRef = collection(firestore, "users");
      const q = query(usersRef, where("email", "==", user.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userDocRef = doc(firestore, "users", userDoc.id);

        // Update the user document with new data
        await updateDoc(userDocRef, userData);

        toast.success("Data has been updated.", {
          position: toast.POSITION.TOP_CENTER,
        });

        // Redirect to home page
        navigate("/active-questions");
      } else {
        toast.error("User document not found.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("You haven't completed your information.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
      <>
        {userExists && userEthnicity && userGender && userCountry && userPostcode && userDOB ? (
            <div className="container mx-auto">
              <div className="flex items-center justify-center">
                <div className="w-3/4 md:w-1/2">
                  <h1 className="text-black text-center text-2xl font-bold my-6">
                    Your account is already completed!
                  </h1>
                </div>
              </div>
            </div>
        ) : (
            <div className="container mx-auto">
              <div className="flex items-center justify-center">
                <div className="w-3/4 md:w-1/2">
                  <h1 className="text-black text-center text-2xl font-bold my-6">
                    Sign Up with Google
                  </h1>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <form
                    className="bg-white rounded px-8 pt-6 pb-8 mb-4 max-w-md w-full"
                    onSubmit={handleModification}
                >
                  <div className="mb-4 flex flex-col">
                    <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="dob">
                      Date of birth:
                    </label>
                    <input
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="dob"
                        type="date"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        required
                    />
                  </div>
                  <div className="mb-4 flex flex-col">
                    <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
                      Gender:
                    </label>
                    <select
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Select gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="mb-4 flex flex-col">
                    <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="country">
                      Country:
                    </label>
                    <select
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required
                    >
                      <option value="">Select country</option>
                      {countries.map((countryName, index) => (
                          <option key={index} value={countryName}>
                            {countryName}
                          </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4 flex flex-col">
                    <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="ethnicity">
                      Ethnicity:
                    </label>
                    <select
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="ethnicity"
                        value={ethnicity}
                        onChange={(e) => setEthnicity(e.target.value)}
                    >
                      <option value="">Select ethnicity</option>
                      <option value="Asian">Asian</option>
                      <option value="Black">Black</option>
                      <option value="White">White</option>
                      <option value="Hispanic">Hispanic</option>
                      <option value="Other Race">Other Race</option>
                    </select>
                  </div>
                  <div className="mb-4 flex flex-col">
                    <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="postcode">
                      Postcode:
                    </label>
                    <input
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="postcode"
                        type="text"
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value)}
                        placeholder="Postcode"
                        required
                    />
                  </div>
                  <ToastContainer/>
                  <div className="flex items-center justify-center">
                    <button
                        className="bg-black hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                      Complete Information
                    </button>
                  </div>
                </form>
              </div>
            </div>
        )}
      </>
  );
}

export default ModifyProfile;
