import axios from 'axios';
import {setupCache} from 'axios-cache-adapter';
import localforage from 'localforage';

// Create a `localforage` instance
const store = localforage.createInstance({
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    name: 'axios-cache'
});

// Create `axios-cache-adapter` instance
const cache = setupCache({
    maxAge: 10 * 60 * 1000, // Cache for 1 hour
    store: store,
    // debug: true
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
    adapter: cache.adapter,
    headers: {
        'Cache-Control': 'max-age=600' // 1 hour, ensures axios respects caching
    }
});

export default api;
