import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {UserAuth} from "../context/AuthContext";
import {Button} from "../components/ui/button";
import {FaCheck} from "react-icons/fa";
import api from '../axiosConfig';

const QuestionPage = () => {
    const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
    const {user} = UserAuth();
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [userAge, setUserAge] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
        const fetchUserAge = async () => {
            try {
                const response = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/users`);
                console.log(response.request.fromCache ? 'Cache hit' : 'Cache miss');
                const userData = response.data.documents.find(doc => doc.fields.user_id.stringValue === user.uid).fields;
                const hasEthnicity = userData && userData.ethnicity && userData.ethnicity.stringValue;
                const hasGender = userData && userData.gender && userData.gender.stringValue;
                const hasCountry = userData && userData.country && userData.country.stringValue;
                const hasPostcode = userData && userData.postcode && userData.postcode.stringValue;
                const hasDOB = userData && userData.date_birth && userData.date_birth.stringValue;
                if (hasEthnicity && hasGender && hasCountry && hasPostcode && hasDOB) {
                    // User is complete, redirect to home page
                    const birthDate = new Date(userData.date_birth.stringValue);
                    const age = Math.floor((new Date() - birthDate) / (365.25 * 24 * 60 * 60 * 1000));
                    setUserAge(age);
                } else {
                    // User isn't complete, redirect to complete profile page to provide additional information
                    navigate("/complete-profile");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserAge();
        }
    }, [user]);

    useEffect(() => {
        const today = new Date();
        if (user) {
            const fetchQuestions = async () => {
                try {
                    const response = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/questions`);
                    console.log(response.request.fromCache ? 'Cache hit' : 'Cache miss');
                    const questionsData = response.data.documents.map(doc => ({
                        id: doc.name.split('/').pop(),
                        ...doc.fields
                    })).filter(question => {
                        const questionDate = new Date(question.date.timestampValue);
                        return questionDate < today && (userAge >= 18 || !question.isAdult.booleanValue);
                    });
                    setQuestions(questionsData);
                } catch (error) {
                    //console.error("Error fetching questions:", error);
                }
            };
            fetchQuestions();
        }
    }, [userAge]);

    useEffect(() => {
        if (user) {
            const fetchAnswers = async () => {
                try {
                    const response = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/answers`);
                    console.log(response.request.fromCache ? 'Cache hit' : 'Cache miss');
                    const answersData = response.data.documents
                        .filter(doc => doc.fields.userId.stringValue === user.uid)
                        .map(doc => doc.fields);
                    setAnswers(answersData);
                } catch (error) {
                    //console.error("Error fetching answers:", error);
                }
            };
            fetchAnswers();
        }
    }, [user]);

    // Function to check if the user has replied to a specific question
    const hasUserReplied = (questionId) => {
        return answers.some((answer) => answer.questionId.stringValue === questionId.stringValue);
    };

    return (
        <div className="bg-white-900 text-white min-h-screen flex flex-col justify-center items-center p-10">
            <h2 className="text-3xl font-semibold mb-6 text-black">Question List</h2>
            <ul className="grid gap-4">
                {questions.map((question) => (
                    <li
                        key={question.id.stringValue}
                        className={`${
                            !hasUserReplied(question.id)
                                ? "bg-blue-500" // Unanswered
                                : question.isActive.booleanValue
                                    ? "bg-green-400" // Answered and results are live (light green)
                                    : "bg-gray-200" // Answered but results not live (light grey)
                        } p-6 rounded-lg shadow-md content-center text-center relative hover:shadow-xl transition duration-300 ease-in-out hover:scale-105`}
                    >
                        <Link
                            to={`/question/${question.id.stringValue}`}
                            className={`${
                                question.isActive.booleanValue ? "text-white" : "text-gray-400"
                            } font-bold hover:underline`}
                        >
                            {question.text.stringValue}
                        </Link>

                        {hasUserReplied(question.id) && (
                            <div className="absolute top-0 right-1 bottom-0 animate-fade-in">
                                <FaCheck color="green" size={20}/>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <div className="flex justify-between my-8">
                <Link to="/suggest-question">
                    <Button className="mx-4 py-6 bg-black text-white" variant="outline">
                        Suggest a question
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default QuestionPage;
