import api from "../axiosConfig";


// Fetch answers for the current question
const fetchAnswers = async (questionId, user, colors, generateTableDict, handlerLabels, calculateSimilarAnswers, countAnswerLabels, getAnswerLabels, setIsMultipleChoice, setChartData, setDictGeneral, setChartGenderDataReverse, setChartGenderData, setChartAgeDataReverse, setChartEthnicityDataReverse, setChartAgeData, setChartEthnicityData) => {
    try {
        const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const answerResponse = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/answers`);
        console.log(answerResponse.request.fromCache ? 'answerResponse Cache hit' : 'answerResponse Cache miss');

        // Filter answers related to the specific questionId
        const answersData = answerResponse.data.documents
            .filter(doc => doc.fields.questionId.stringValue === String(questionId.questionId))
            .map(doc => doc.fields);

        const answerLabels = answersData.map(answer => {
            if (answer.answer.integerValue !== undefined) {
                return answer.answer.integerValue;
            } else {
                return answer.answer.stringValue;
            }
        });

        const answerCounts = {};

        answerLabels.forEach((answer) => {
            if (answerCounts[answer]) {
                answerCounts[answer]++;
            } else {
                answerCounts[answer] = 1;
            }
        });
        if (answersData[0].answer.length > 1) {
            setIsMultipleChoice(true);
        }

        const labels = await handlerLabels(answerCounts);
        await Promise.all(labels.map(async (label) => {
            if (answerCounts[label]) {
                answerCounts[label] += 0;
            } else {
                answerCounts[label] = 0;
            }
        }));
        const counts = Object.values(answerCounts);

        setDictGeneral(generateTableDict(answerCounts));

        setChartData({
            labels: labels,
            datasets: [
                {
                    data: counts,
                    backgroundColor: "blue", //'rgba(54, 162, 235, 0.8)',
                    borderWidth: 1,
                },
            ],
        });

        const getChartDataByFilter = async (
            collectionName,
            filterField,
            filterValue,
            label
        ) => {
            const getDataByQuery = async (queryParams) => {
                const response = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/${collectionName}`);
                console.log(response.request.fromCache ? `${collectionName} Cache hit` : `${collectionName} Cache miss`);
                const data = response.data.documents
                    .filter(doc => queryParams.every(param => {
                        const [field, operator, value] = param;
                        return doc.fields[field].stringValue === String(value);
                    }))
                    .map(doc => doc.fields);
                return data.map((item) => {
                    for (const key in item) {
                        if (item[key] && typeof item[key] === 'object' && 'stringValue' in item[key]) {
                            item[key] = item[key].stringValue;
                        }
                    }
                    return item;
                })
            };

            // Get the answers for the current question and user
            const data_user = await getDataByQuery([
                ["questionId", "==", String(questionId.questionId)],
                ["userId", "==", user.uid]
            ]);

            // Get the answers for the current question
            const data_ = await getDataByQuery([
                ["questionId", "==", String(questionId.questionId)]
            ]);
            // Get the labels for the current question
            const answerLabels_ = getAnswerLabels(data_, filterField);
            const answerCounts_ = countAnswerLabels(answerLabels_);

            const data = await getDataByQuery([
                ["questionId", "==", String(questionId.questionId)],
                [filterField, "==", filterValue]
            ]);

            //const answerLabels = data.map((answer) => answer.answer.integerValue);
            const answerLabels = data.map(answer => {
                if (answer.answer.integerValue !== undefined) {
                    return answer.answer.integerValue;
                } else {
                    return answer.answer;
                }
            });

            const answerCounts = countAnswerLabels(answerLabels);
            const result = await Promise.all(
                labels.map(async (label) => answerCounts[label])
            );
            calculateSimilarAnswers(filterField, data_user, label, answerCounts_);
            return {
                label: label,
                data: result,
                borderWidth: 1,
            };
        };

        const chartDataByGender = [
            await getChartDataByFilter(
                "answers",
                "gender",
                "female",
                "Female"
            ),
            await getChartDataByFilter("answers", "gender", "male", "Male"),
        ];

        setChartGenderData({
            labels: labels,
            datasets: chartDataByGender,
        });

        const chartDataByAge = [
            await getChartDataByFilter(
                "answers",
                "age_group",
                "Under 18",
                "Under 18"
            ),
            await getChartDataByFilter(
                "answers",
                "age_group",
                "18-25",
                "18-25"
            ),
            await getChartDataByFilter(
                "answers",
                "age_group",
                "25-35",
                "25-35"
            ),
            await getChartDataByFilter(
                "answers",
                "age_group",
                "35-45",
                "35-45"
            ),
            await getChartDataByFilter(
                "answers",
                "age_group",
                "45-55",
                "45-55"
            ),
            await getChartDataByFilter(
                "answers",
                "age_group",
                "55-65",
                "55-65"
            ),
            await getChartDataByFilter(
                "answers",
                "age_group",
                "65-75",
                "65-75"
            ),
            await getChartDataByFilter(
                "answers",
                "age_group",
                "75+",
                "75+"
            ),
        ];

        setChartAgeData({
            labels: labels,
            datasets: chartDataByAge,
        });

        const chartDataByEthnicity = [
            await getChartDataByFilter(
                "answers",
                "ethnicity",
                "Asian",
                "Asian"
            ),
            await getChartDataByFilter(
                "answers",
                "ethnicity",
                "Black",
                "Black"
            ),
            await getChartDataByFilter(
                "answers",
                "ethnicity",
                "Hispanic",
                "Hispanic"
            ),
            await getChartDataByFilter(
                "answers",
                "ethnicity",
                "White",
                "White"
            ),
            await getChartDataByFilter(
                "answers",
                "ethnicity",
                "Other Race",
                "Other"
            ),
        ];

        setChartEthnicityData({
            labels: labels,
            datasets: chartDataByEthnicity,
        });


        // Add reverse charts
        const getGenderReverseChartData = async (collectionName, questionId) => {
            // Helper function to get data by query
            const getDataByQuery = async (queryParams) => {
                const response = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/${collectionName}`);
                console.log(response.request.fromCache ? 'Cache hit' : 'Cache miss');
                const questionData = response.data.documents
                    .filter(doc => queryParams.every(param => {
                        const [field, operator, value] = param;
                        return doc.fields[field].stringValue === String(value);
                    }))
                return questionData.map(doc => ({
                    answer: doc.fields.answer.integerValue !== undefined ? doc.fields.answer.integerValue : doc.fields.answer.stringValue,
                    gender: doc.fields.gender.stringValue
                }));
            };

            // Retrieve all answers for the question
            const data = await getDataByQuery([
                ["questionId", "==", String(questionId.questionId)]
            ]);

            // Organize data by gender, then by answer
            const genderData = data.reduce((acc, item) => {
                const {answer, gender} = item;
                if (!acc[gender]) acc[gender] = {};
                if (!acc[gender][answer]) acc[gender][answer] = 0;
                acc[gender][answer]++;
                return acc;
            }, {});

            // Extract unique answers to form the labels
            const answerOptions = new Set(data.map(item => item.answer));
            const labels = Array.from(answerOptions);

            // Create datasets for each answer option
            const datasets = labels.map((label, index) => ({
                label,
                data: [],
                backgroundColor: colors[index % colors.length],  // Use fixed colors
                borderWidth: 1,
            }));

            const genders = Object.keys(genderData); // Should be ['female', 'male'] if both are always present
            genders.forEach(gender => {
                labels.forEach((label, index) => {
                    datasets[index].data.push(genderData[gender][label] || 0);
                });
            });

            return {
                labels: genders,
                datasets: datasets,
            };
        };

        getGenderReverseChartData("answers", questionId).then(chartData => {
            setChartGenderDataReverse(chartData);
        });

        const getAgeReverseChartData = async (collectionName, questionId) => {
            // Helper function to get data by query
            const getDataByQuery = async (queryParams) => {
                const response = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/${collectionName}`);
                console.log(response.request.fromCache ? 'Cache hit' : 'Cache miss');
                return response.data.documents
                    .filter(doc => queryParams.every(param => {
                        const [field, operator, value] = param;
                        return doc.fields[field].stringValue === String(value);
                    }))
                    .map(doc => ({
                        answer: doc.fields.answer.integerValue !== undefined ? doc.fields.answer.integerValue : doc.fields.answer.stringValue,
                        age_group: doc.fields.age_group.stringValue
                    }));

            };

            // Retrieve all answers for the question
            const data = await getDataByQuery([
                ["questionId", "==", String(questionId.questionId)]
            ]);

            // Organize data by age group, then by answer
            const ageGroupData = data.reduce((acc, item) => {
                const {answer, age_group} = item;
                if (!acc[age_group]) acc[age_group] = {};
                if (!acc[age_group][answer]) acc[age_group][answer] = 0;
                acc[age_group][answer]++;
                return acc;
            }, {});

            // Extract unique answers to form the labels
            const answerOptions = new Set(data.map(item => item.answer));
            const labels = Array.from(answerOptions);

            // Create datasets for each answer option
            const datasets = labels.map((label, index) => ({
                label,
                data: [],
                backgroundColor: colors[index % colors.length],  // Use fixed colors
                borderWidth: 1,
            }));

            const ageGroups = Object.keys(ageGroupData);
            ageGroups.forEach(ageGroup => {
                labels.forEach((label, index) => {
                    datasets[index].data.push(ageGroupData[ageGroup][label] || 0);
                });
            });

            return {
                labels: ageGroups,
                datasets: datasets,
            };
        };

        getAgeReverseChartData("answers", questionId).then(chartData => {
            setChartAgeDataReverse(chartData);
        });

        const getEthnicityReverseChartData = async (collectionName, questionId) => {
            // Helper function to get data by query
            const getDataByQuery = async (queryParams) => {
                const response = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/${collectionName}`);
                console.log(response.request.fromCache ? 'Cache hit' : 'Cache miss');
                return response.data.documents
                    .filter(doc => queryParams.every(param => {
                        const [field, operator, value] = param;
                        return doc.fields[field].stringValue === String(value);
                    }))
                    .map(doc => ({
                        answer: doc.fields.answer.integerValue !== undefined ? doc.fields.answer.integerValue : doc.fields.answer.stringValue,
                        ethnicity: doc.fields.ethnicity.stringValue
                    }));
            };

            // Retrieve all answers for the question
            const data = await getDataByQuery([
                ["questionId", "==", String(questionId.questionId)]
            ]);

            // Organize data by ethnicity, then by answer
            const ethnicityData = data.reduce((acc, item) => {
                const {answer, ethnicity} = item;
                if (!acc[ethnicity]) acc[ethnicity] = {};
                if (!acc[ethnicity][answer]) acc[ethnicity][answer] = 0;
                acc[ethnicity][answer]++;
                return acc;
            }, {});

            // Extract unique answers to form the labels
            const answerOptions = new Set(data.map(item => item.answer));
            const labels = Array.from(answerOptions);

            // Create datasets for each answer option
            const datasets = labels.map((label, index) => ({
                label,
                data: [],
                backgroundColor: colors[index % colors.length],  // Use fixed colors
                borderWidth: 1,
            }));

            const ethnicity = Object.keys(ethnicityData);
            ethnicity.forEach(ethnicity => {
                labels.forEach((label, index) => {
                    datasets[index].data.push(ethnicityData[ethnicity][label] || 0);
                });
            });

            return {
                labels: ethnicity,
                datasets: datasets,
            };
        };

        getEthnicityReverseChartData("answers", questionId).then(chartData => {
            setChartEthnicityDataReverse(chartData);
        });


    } catch (error) {
        //console.error("Error in fetchAnswers:", error);
    }
};

export default fetchAnswers;