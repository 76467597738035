import React, {useState, useEffect} from "react";
import {UserAuth} from "../context/AuthContext";
import {firestore} from "../firebase";
import {useParams, Link} from "react-router-dom";
import {collection, query, getDocs, addDoc, where} from "@firebase/firestore";
import {Bar} from "react-chartjs-2";
import {Chart, registerables} from "chart.js";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import ThankfulMessage from "./ThankfulMessage";
import {Button} from "./ui/button";
import api from "../axiosConfig";
import fetchAnswers from "./fetchAnswers";
import {toast} from "react-toastify";

Chart.register(ChartjsPluginStacked100, ...registerables);

const QuestionPage = () => {
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [question, setQuestion] = useState(null);
    const questionId = useParams();
    const [answersCnt, setAnswersCnt] = useState(0);
    const [answerOptions, setAnswerOptions] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [showThankfulMessage, setShowThankfulMessage] = useState(false);
    const [isMultipleChoice, setIsMultipleChoice] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [chartGenderData, setChartGenderData] = useState(null);
    const [chartGenderDataReverse, setChartGenderDataReverse] = useState(null);
    const [chartAgeData, setChartAgeData] = useState(null);
    const [chartAgeDataReverse, setChartAgeDataReverse] = useState(null);
    const [chartEthnicityData, setChartEthnicityData] = useState(null);
    const [chartEthnicityDataReverse, setChartEthnicityDataReverse] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [ageGroup, setAgeGroup] = useState(null);
    const [minAmountOfAnswersReached, setMinAmountOfAnswersReached] =
        useState(false);
    const [isCategoricalQuestion, setIsCategoricalQuestion] = useState(true);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(100);
    const [stepValue, setStepValue] = useState(minValue);
    const [selectedValue, setSelectedValue] = useState(minValue);
    const [dictGeneral, setDictGeneral] = useState(null);
    const [dictGender, setDictGender] = useState(null);
    const [dictAge, setDictAge] = useState(null);
    const [dictEthnicity, setDictEthnicity] = useState(null);
    const [similarGenderAnswers, setSimilarGenderAnswers] = useState(null);
    const [similarAgeAnswers, setSimilarAgeAnswers] = useState(null);
    const [similarEthnicityAnswers, setSimilarEthnicityAnswers] = useState(null);
    const colors = [
        'rgba(255, 99, 132, 0.5)',   // Red
        'rgba(54, 162, 235, 0.5)',   // Blue
        'rgba(255, 206, 86, 0.5)',   // Yellow
        'rgba(75, 192, 192, 0.5)',   // Green
        'rgba(153, 102, 255, 0.5)',  // Purple
        'rgba(255, 159, 64, 0.5)'    // Orange
    ];
    const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

    const handleSliderChange = (event) => {
        setSelectedValue(parseInt(event.target.value, 10));
        setSelectedAnswers(parseInt(event.target.value, 10));
    };


    const handlerLabels = async (answerCounts) => {

        const questionResponse = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/questions`);
        console.log(questionResponse.request.fromCache ? 'questionResponse Cache hit' : 'questionResponse Cache miss');
        const questionData = questionResponse.data.documents.find(doc => doc.fields.id.stringValue === questionId.questionId).fields;
        setQuestion(questionData.text.stringValue);
        let labels = [];
        if (questionData.type && questionData.type.stringValue === "con" &&
            questionData.min &&
            questionData.max &&
            questionData.interval) {

            const start = Number(questionData.min.integerValue);
            const end = Number(questionData.max.integerValue);
            const step = Number(questionData.interval.integerValue);

            for (let i = start; i <= end; i += step) {
                labels.push(i);
            }
        }

        if (questionData.type.stringValue === "cat") {
            labels = Object.keys(answerCounts).reverse();
        }

        return labels;
    };

    const Table = ({data}) => {
        return (
            <div className="w-full md:w-full">
                <table className="table-auto mx-auto border-collapse border text-center">
                    <thead>
                    <tr>
                        <th className="px-4 py-2 bg-gray-900 text-white">Options</th>
                        <th className="px-4 py-2 bg-gray-900 text-white">Answers</th>
                        <th className="px-4 py-2 bg-gray-900 text-white">%</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="hover:bg-green-700 text-black">
                            <td className="border px-4 py-2">{item.label}</td>
                            <td className="border px-4 py-2">{item.total}</td>
                            <td className="border px-4 py-2">{item.percentage}%</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const generateTableDict = (answerCounts) => {
        const total = Object.values(answerCounts).reduce(
            (acc, cur) => acc + cur,
            0
        );
        return Object.keys(answerCounts).map((key) => ({
            label: key,
            total: answerCounts[key],
            percentage: ((answerCounts[key] / total) * 100).toFixed(1),
        }));
    };

    const {user} = UserAuth();

    const calculateAgeGroup = (dateOfBirth) => {
        // Calculate the user's age based on their date of birth
        const dob = new Date(dateOfBirth);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();

        if (age < 18) {
            return "Under 18";
        } else if (age >= 18 && age < 25) {
            return "18-25";
        } else if (age >= 25 && age < 35) {
            return "25-35";
        } else if (age >= 35 && age < 45) {
            return "35-45";
        } else if (age >= 45 && age < 55) {
            return "45-55";
        } else if (age >= 55 && age < 65) {
            return "55-65";
        } else if (age >= 65 && age < 75) {
            return "65-75";
        } else {
            return "75+";
        }
    };


    const fetchUser = async () => {
        try {
            if (user.uid) {
                const userResponse = await api.get(`https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/users`);
                console.log(userResponse.request.fromCache ? 'userResponse Cache hit' : 'userResponse Cache miss');
                const usersData = await userResponse.data.documents.find(doc => doc.fields.user_id.stringValue === user.uid).fields;
                const birthDate = new Date(usersData.date_birth.stringValue);
                const ageGroup = calculateAgeGroup(birthDate);
                setAgeGroup(ageGroup);
                setUserInfo(usersData);
                setIsLoadingUser(false);
            } else {
                setIsLoadingUser(true);
            }
        } catch (error) {
            //console.error("Error in fetchUser:", error);
            setIsLoadingUser(true);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [user.uid]);

    const getAnswerLabels = (data, filterField) => {
        if (filterField === "gender") {
            return data.map((answer) => answer.gender);
        }
        if (filterField === "age_group") {
            return data.map((answer) => answer.age_group);
        }
        if (filterField === "ethnicity") {
            return data.map((answer) => answer.ethnicity);
        }
        return [];
    };

    const countAnswerLabels = (answerLabels) => {
        // Count the number of times each answer appears
        const answerCounts = {};
        answerLabels.forEach((answer) => {
            if (answerCounts[answer]) {
                answerCounts[answer]++;
            } else {
                answerCounts[answer] = 1;
            }
        });
        return answerCounts;
    };

    const calculateSimilarAnswers = (
        filterField,
        data_user,
        label,
        answerCounts
    ) => {
        for (const key in data_user[0]) {
            if (data_user[0][key] && typeof data_user[0][key] === 'object' && 'stringValue' in data_user[0][key]) {
                data_user[0][key] = data_user[0][key].stringValue;
            }
        }
        const sum = Object.values(answerCounts).reduce(
            (acc, answer) => acc + answer,
            0
        );
        if (filterField === "gender") {
            setDictGender(generateTableDict(answerCounts));
            if (data_user[0].gender === label.toLowerCase()) {
                setSimilarGenderAnswers(
                    ((answerCounts[data_user[0].gender] / sum) * 100).toFixed(1)
                );
            }
        }

        if (filterField === "age_group") {
            setDictAge(generateTableDict(answerCounts));
            if (data_user[0].age_group.toLowerCase() === label.toLowerCase()) {
                setSimilarAgeAnswers(
                    ((answerCounts[data_user[0].age_group] / sum) * 100).toFixed(1)
                );
            }
        }

        if (filterField === "ethnicity") {
            setDictEthnicity(generateTableDict(answerCounts));
            if (data_user[0].ethnicity.toLowerCase() === label.toLowerCase()) {
                setSimilarEthnicityAnswers(
                    ((answerCounts[data_user[0].ethnicity] / sum) * 100).toFixed(1)
                );
            }
        }
    };

    const chartOptions = {
        options: {
            responsive: true,
            maintainAspectRatio: false,
        },
        //barValueSpacing: 30,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
                grid: {
                    display: true,
                },
                ticks: {
                    stepSize: 1,
                    color: "black", // Y-axis label color
                    font: {
                        size: 14, // Y-axis label font size
                    },
                },
            },
            x: {
                stacked: true,
                grid: {
                    display: true,
                },
                ticks: {
                    color: "black", // X-axis label color
                    font: {
                        size: 14, // X-axis label font size
                    },
                },
            },
        },
        animation: {
            duration: 300, // Animation duration in milliseconds
            easing: "easeInOutQuart", // Easing function for animation
        },
        // ... (other options)
    };

    useEffect(() => {
        if (!isLoadingUser && minAmountOfAnswersReached && showThankfulMessage) {
            fetchAnswers(questionId, user, colors, generateTableDict, handlerLabels, calculateSimilarAnswers, countAnswerLabels, getAnswerLabels, setIsMultipleChoice, setChartData, setDictGeneral, setChartGenderDataReverse, setChartGenderData, setChartAgeDataReverse, setChartEthnicityDataReverse, setChartAgeData, setChartEthnicityData);
        }
        //processQuestions();
    }, [isLoadingUser, questionId.questionId, minAmountOfAnswersReached, showThankfulMessage]);


    const fetchNextQuestion = async () => {
        try {
            const answeredQuestionsCollection = collection(firestore, "answers");
            const answeredQuestionsQuery = query(
                answeredQuestionsCollection,
                where("userId", "==", user.uid)
            );
            const answeredQuestionsSnapshot = await getDocs(answeredQuestionsQuery);
            const answeredQuestionIds = answeredQuestionsSnapshot.docs.map(
                (doc) => doc.data().questionId
            );

            const questionsCollection = collection(firestore, "questions");
            const questionQuery = query(
                questionsCollection,
                where("id", "==", String(questionId.questionId))
            );
            const latestQuestionSnapshot = await getDocs(questionQuery);

            const totalAnswersQuery = query(
                answeredQuestionsCollection,
                where("questionId", "==", String(questionId.questionId))
            );
            const totalAnswersSnapshot = await getDocs(totalAnswersQuery);
            setAnswersCnt(totalAnswersSnapshot.size);

            setMinAmountOfAnswersReached(totalAnswersSnapshot.size >= 101);

            if (latestQuestionSnapshot.empty) {
                // No questions available
                setShowThankfulMessage(false);
            } else {
                let nextQuestionFound = false;
                latestQuestionSnapshot.forEach((doc) => {
                    const data = doc.data();

                    if (
                        !answeredQuestionIds.includes(questionId.questionId) &&
                        !nextQuestionFound
                    ) {
                        // Check if the user has not answered this question
                        setQuestion(data.text);
                        setAnswerOptions(data.options);
                        setShowThankfulMessage(false);
                        nextQuestionFound = true;
                        if (data.type === "con") {
                            setIsCategoricalQuestion(false);
                            setMaxValue(data.max);
                            setMinValue(data.min);
                            setStepValue(data.interval);
                        }
                    }
                });

                if (!nextQuestionFound) {
                    setShowThankfulMessage(true);
                }
            }
        } catch (error) {
            //console.error("Error in fetchNextQuestion: ", error);
        }
    };
    useEffect(() => {
        if (!isLoadingUser && user) {
            fetchNextQuestion();
        }
    }, [isLoadingUser, user.uid]);

    const handleAnswerSelect = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        const answersCollection = collection(firestore, "answers");
        const newAnswer = {
            answer: selectedAnswers,
            questionId: questionId.questionId,
            userId: user.uid,
            timestamp: new Date().toISOString(),
            gender: userInfo.gender.stringValue,
            postcode: userInfo.postcode.stringValue,
            ethnicity: userInfo.ethnicity.stringValue,
            age_group: ageGroup,
        };
        try {
            addDoc(answersCollection, newAnswer)
                .then(() => {
                    toast.success("Answer submitted successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setSelectedAnswers([]);
                    fetchNextQuestion(); // Move to the next question after submission
                    setSubmitting(false);
                })
                .catch(() => {
                    toast.error("Error submitting answer.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setSelectedAnswers([]);
                    setSubmitting(false);
                });
        } catch (error) {
            //console.error("Error sending answers: ", error);
            navigate("/complete-profile");
        }
    };

    const handleOptionChange = (option) => {
        const selectedIndex = selectedAnswers.indexOf(option);
        if (selectedIndex === -1) {
            setSelectedAnswers(...selectedAnswers, option);
        } else {
            setSelectedAnswers(selectedAnswers.filter((o) => o !== option));
        }
    };

    return (
        // Display the actual results
        <div className="text-white min-h-screen flex flex-col justify-center items-center py-4">
            {showThankfulMessage ? (
                minAmountOfAnswersReached ? (
                    isLoadingUser ? (
                        <div>Loading user data...</div>
                    ) : (
                        <div className="w-full max-w-screen-xl">
                            <h1 className="text-black text-2xl font-bold mb-4 px-4 text-center">
                                {question}
                            </h1>

                            <h2 className="text-black text-center py-4">
                                <b> Overview </b>
                            </h2>

                            <div className="flex flex-col lg:flex-row justify-center items-center px-6 pt-4">
                                <div className="mb-4 lg:mb-0 lg:pr-6 w-full md:w-1/2">
                                    <div className="bg-white p-6 rounded">
                                        {chartData ? (
                                            <Bar
                                                data={chartData}
                                                height="300px"
                                                options={chartOptions}
                                            />
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                </div>

                                <div className="lg:w-1/2 lg:pl-2 w-full md:w-full lg:px-40">
                                    <div className="justify-center items-center mb-6">
                                        {dictGeneral ? (
                                            <Table data={dictGeneral}/>
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>

                                    <div className="mb-6">
                                        <h2 className="text-center py-4 text-black">
                                            There are {answersCnt} people answered
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <h2 className="text-center py-4 text-black">
                                {" "}
                                <b> Gender </b>{" "}
                            </h2>

                            <div className="flex flex-col lg:flex-row justify-center items-center px-6">
                                <div
                                    className="flex flex-col lg:flex-row lg:justify-center lg:items-start w-full lg:space-x-1 mb-4 lg:mb-0 lg:pr-1">
                                    <div
                                        className="bg-white p-6 rounded w-full lg:w-1/2"> {/* Adjusted for side by side layout */}
                                        {chartGenderData ? (
                                            <Bar
                                                data={chartGenderData}
                                                height="400px"
                                                options={{
                                                    //@ts-ignore
                                                    indexAxis: "x",
                                                    plugins: {
                                                        stacked100: {enable: true},
                                                    },
                                                }
                                                }

                                            />
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                    <div
                                        className="bg-white p-6 rounded mt-4 lg:mt-0 w-full lg:w-1/2"> {/* Adjusted for side by side layout */}
                                        {chartGenderDataReverse ? (
                                            <Bar
                                                data={chartGenderDataReverse}
                                                height="400px"
                                                options={{
                                                    //@ts-ignore
                                                    indexAxis: "x",
                                                    plugins: {
                                                        stacked100: {enable: true},
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                </div>

                                <div className="lg:w-1/2 lg:pl-6 w-full md:w-full lg:px-40">
                                    <div className="justify-center items-center mb-6">
                                        {dictGender ? <Table data={dictGender}/> : <div>Loading...</div>}
                                    </div>

                                    <div className="mb-6">
                                        <h2 className="text-center py-4 text-black">
                                            {similarGenderAnswers}% of your gender answered the same as
                                            you
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <h2 className="text-center item-center py-4 text-black">
                                {" "}
                                <b> Age </b>{" "}
                            </h2>

                            <div className="flex flex-col lg:flex-row justify-center items-center px-6">
                                <div
                                    className="flex flex-col lg:flex-row lg:justify-center lg:items-start w-full lg:space-x-1 mb-4 lg:mb-0 lg:pr-1">
                                    <div
                                        className="bg-white p-6 rounded w-full lg:w-1/2">
                                        {chartAgeData ? (
                                            <Bar
                                                data={chartAgeData}
                                                height="400px"
                                                options={{
                                                    //@ts-ignore
                                                    indexAxis: "x",
                                                    plugins: {
                                                        stacked100: {enable: true},
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                    <div
                                        className="bg-white p-6 rounded mt-4 lg:mt-0 w-full lg:w-1/2"> {/* Adjusted for side by side layout */}
                                        {chartAgeDataReverse ? (
                                            <Bar
                                                data={chartAgeDataReverse}
                                                height="400px"
                                                options={{
                                                    //@ts-ignore
                                                    indexAxis: "x",
                                                    plugins: {
                                                        stacked100: {enable: true},
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                </div>

                                <div className="lg:w-1/2 lg:pl-6 w-full md:w-full lg:px-40">
                                    <div className="justify-center items-center mb-6">
                                        {dictAge ? <Table data={dictAge}/> : <div>Loading...</div>}
                                    </div>

                                    <div className="mb-6">
                                        <h2 className="text-center py-4 text-black">
                                            {similarAgeAnswers}% of your age group answered the same as
                                            you
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <h2 className="text-center item-center py-4 text-black">
                                {" "}
                                <b> Ethnicity </b>{" "}
                            </h2>

                            <div className="flex flex-col lg:flex-row justify-center items-center px-6">
                                <div
                                    className="flex flex-col lg:flex-row lg:justify-center lg:items-start w-full lg:space-x-1 mb-4 lg:mb-0 lg:pr-1">
                                    <div
                                        className="bg-white p-6 rounded w-full lg:w-1/2">
                                        {chartEthnicityData ? (
                                            <Bar
                                                data={chartEthnicityData}
                                                height="400px"
                                                options={{
                                                    //@ts-ignore
                                                    indexAxis: "x",
                                                    plugins: {
                                                        stacked100: {
                                                            enable: true,
                                                        },
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                    <div
                                        className="bg-white p-6 rounded mt-4 lg:mt-0 w-full lg:w-1/2"> {/* Adjusted for side by side layout */}
                                        {chartEthnicityDataReverse ? (
                                            <Bar
                                                data={chartEthnicityDataReverse}
                                                height="400px"
                                                options={{
                                                    //@ts-ignore
                                                    indexAxis: "x",
                                                    plugins: {
                                                        stacked100: {enable: true},
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </div>
                                </div>

                                <div className="lg:w-1/2 lg:pl-6 w-full md:w-full lg:px-40">
                                    <div className="justify-center items-center mb-6">
                                        {dictEthnicity ? (
                                            <Table data={dictEthnicity}/>
                                        ) : (
                                            <div>Loading...</div>
                                        )}

                                        <div className="mb-6">
                                            <h2 className="text-center py-4 text-black">
                                                {similarEthnicityAnswers}% of your ethnicity group
                                                answered the same as you
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    // Display the thankful message
                    <ThankfulMessage answers={answersCnt}/>
                )
            ) : (
                // Display the question form
                question && (
                    <form className="bg-gray-700 p-8 rounded-lg shadow-lg mx-2 my-4">
                        <h2 className="text-2xl font-bold mb-4">{question}</h2>
                        <ul className="space-y-2">
                            {isCategoricalQuestion ? (
                                <ul className="space-y-2">
                                    {answerOptions.map((option, index) => (
                                        <li key={index} className="flex items-center">
                                            <input
                                                id={`option-${index}`}
                                                type={isMultipleChoice ? "checkbox" : "radio"}
                                                name="answer"
                                                value={option}
                                                checked={selectedAnswers.includes(option)}
                                                onChange={() => handleOptionChange(option)}
                                                className="h-4 w-4 text-green-500 focus:ring-green-400 border-gray-300 rounded"
                                            />
                                            <label
                                                htmlFor={`option-${index}`}
                                                className="ml-2 text-sm"
                                            >
                                                {option}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="items-center justify-center flex flex-col">
                                    <input
                                        className="md:w-96 w-48 mb-4"
                                        type="range"
                                        min={minValue}
                                        max={maxValue}
                                        step={stepValue}
                                        value={selectedValue}
                                        onChange={handleSliderChange}
                                    />
                                    <p>Selected Value: {selectedValue}</p>
                                </div>
                            )}
                        </ul>
                        <div className="flex items-center justify-center">
                            <button
                                type="button"
                                disabled={submitting}
                                onClick={handleAnswerSelect}
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4"
                            >
                                {submitting ? "Submitting..." : "Submit"}
                            </button>
                        </div>
                    </form>
                )
            )}
            <div className="flex justify-between my-4">
                <Link to="/active-questions">
                    <Button
                        className="mx-4 py-4 bg-black hover:bg-accent text-white hover:text-black"
                        variant="outline"
                    >
                        Active Questions
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default QuestionPage;
