import React, {useState} from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { FaGlobe } from "react-icons/fa";

const Navbar = () => {
    const navigate = useNavigate();
    const { user, logOut } = UserAuth();
    const [nav, setNav] = useState(false);
    const [showTranslate, setShowTranslate] = useState(false);

    const handleSignOut = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            //console.log(error);
        }
    };

    const handleNav = () => {
        setNav(!nav);
    };

    const toggleTranslateWidget = () => {
        setShowTranslate(!showTranslate);
    };

    return (
        <nav className="flex w-full justify-between items-center h-24 px-5 text-black bg-green-200">
            <Link to="/" className="flex items-center text-3xl font-bold text-black">
                <img src="/logo_101.png" alt="101 Humans" className="h-24 flex-shrink-0" />
            </Link>
            <ul className="hidden md:flex">
                <li className="p-4">
                    <Link to="/blog" className="p-4">Blog</Link>
                </li>
                {!user?.uid && (
                    <li className="p-4">
                        <Link to="/signup">Sign Up</Link>
                    </li>
                )}
                <li className="p-4">
                    {user?.uid ? (
                        <button onClick={handleSignOut}>Log Out</button>
                    ) : (
                        <Link to="/signin">Sign In</Link>
                    )}
                </li>
                <li className="p-4 flex items-center relative">
                    <FaGlobe className="text-xl mr-2 cursor-pointer" onClick={toggleTranslateWidget} type="button"/>
                    <div id="google_translate_element" style={{
                        display: showTranslate ? 'block' : 'none',
                        position: 'absolute',
                        top: '100%',
                        marginLeft: '-100px',
                        zIndex: 10
                    }}></div>
                </li>
            </ul>

            <div onClick={handleNav} className="block md:hidden">
                {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}

                <ul
                    className={
                        nav
                            ? "fixed left-0 top-0 w-full h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-50"
                            : "ease-in-out duration-500 fixed left-[-100%] top-0 w-full h-full border-r border-r-gray-900 bg-[#000300]"
                    }
                >
                    <div className="flex justify-between items-center h-24 mx-auto px-5 text-white max-w-screen-xl">
                        <Link
                            to="/"
                            className="flex items-center text-3xl font-bold text-white"
                        >
                            <img
                                src="/logo_101.png"
                                alt="101 Humans"
                                className="h-10 flex-shrink-0"
                            />
                        </Link>
                        <AiOutlineClose size={20} onClick={handleNav}/>
                    </div>

                    <li className="p-4 text-white">
                        <Link to="/blog">Blog</Link>
                    </li>
                    {!user?.uid && (
                        <li className="p-4 text-white">
                            <Link to="/signup">Sign Up</Link>
                        </li>
                    )}
                    <li className="p-4 text-white">
                        {user?.uid ? (
                            <button onClick={handleSignOut}>Log Out</button>
                        ) : (
                            <Link to="/signin">Sign In</Link>
                        )}
                    </li>

                    <li className="p-4 text-white relative">

                            <FaGlobe className="text-xl mr-2 cursor-pointer" onClick={toggleTranslateWidget}
                                     type="button"/>
                            <div id="google_translate_element" style={{
                                display: showTranslate ? 'block' : 'none',
                                position: 'absolute',
                                top: '100%',
                                marginLeft: '-100px',
                                zIndex: 10
                            }}></div>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
