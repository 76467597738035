// src/pages/Blog.js
import React from "react";
import { useParams } from "react-router-dom";
import {Link} from "react-router-dom";

const blogPosts = [
    {
        id: 1,
        title: "Welcome to 101 Huamns.com",
        content: (
            <>
                <h3 className="text-2xl font-semibold mt-5">Welcome to 101humans.com: Your New Playground for Curiosity
                    and Anonymity</h3>
                <p className="mt-4">
                    Ever wondered what would happen if we stripped away the embarrassment and shame from asking those
                    burning questions?
                    You know, the ones that pop into your head late at night, leaving you staring at the ceiling and
                    pondering life’s great mysteries.
                    Welcome to 101humans.com, the social experiment that takes your curiosity seriously—but not too
                    seriously, because where’s the fun in that?
                </p>
                <h4 className="text-xl font-semibold mt-8">What If You Could Ask Anything Without Shame?</h4>
                <p className="mt-4">
                    Imagine a world where you could ask any question without worrying about judgment. That’s what we’re
                    all about.
                    On 101humans.com, no question is too weird, too silly, or too taboo. And the best part? It’s
                    completely anonymous.
                    Your curiosity is safe with us.
                </p>
                <h4 className="text-xl font-semibold mt-8">Busting Myths and Confirming Crazy Stories</h4>
                <p className="mt-4">
                    We’ve all heard those bizarre statistics and urban legends. You know, like how 8 out of 10 people
                    believe they are better drivers than the average person.
                    Really? If everyone thinks they’re above average, who’s driving us nuts on the roads? We’re here to
                    find out if these tales hold any truth.
                    Is it the men or the women who are more confident behind the wheel? Do younger drivers really think
                    they're the kings of the road, or do older drivers
                    wave their seasoned wisdom around like a badge of honour?
                </p>
                <h4 className="text-xl font-semibold mt-8">Personal Insights, Just for You</h4>
                <p className="mt-4">
                    Every question you propose will uncover unique personal insights. Want to know if your bizarre
                    habits are shared by others or if you’re truly one of a kind?
                    We’ve got you covered. Each query helps us build a fascinating picture of human behaviour, and the
                    results are as enlightening as they are entertaining.
                </p>
                <h4 className="text-xl font-semibold mt-8">Honesty Is the Best Policy</h4>
                <p className="mt-4">
                    This whole gig only works if you’re honest. Be real with us, and you’ll get to see how you measure
                    up against the rest of the world.
                    Fib a little, and you’re just cheating yourself out of some eye-opening revelations. Plus, where’s
                    the fun in that?
                </p>
                <h4 className="text-xl font-semibold mt-8">Meet the Curious Minds Behind 101humans.com</h4>
                <p className="mt-4">
                    Who are we? Individuals obsessed with social norms and the taboos that vary from culture to culture.
                    Our mission is to provide you with unique insights into different ways of life, helping to bridge
                    gaps and prove that, deep down, we’re not all that different.
                    We believe that by understanding each other a little better, we can bring people closer together,
                    one question at a time.
                </p>
                <p className="mt-4">
                    So, what are you waiting for? Dive into 101humans.com, ask those questions you’ve been too shy to
                    voice, and join us on this journey of discovery.
                    Who knows? You might just find out that your quirks are a lot more common than you thought.
                </p>
                <p className="mt-4 font-semibold">Welcome to the curious world of 101humans. Let’s get questioning!</p>
            </>
        )
    },
    
];

const Blog = () => {
    const { blogId } = useParams();
    const post = blogPosts.find(post => post.id === parseInt(blogId));

    if (!post) {
        return <div className="text-xl text-center text-gray-500 mt-12">Blog post not found</div>;
    }

    return (
        <div className="p-5 my-10 max-w-4xl mx-auto font-sans bg-white rounded-lg shadow-md">
            <h1 className="text-4xl mb-10 text-gray-800 text-center">{post.title}</h1>
            <div className="prose prose-lg text-gray-700">
                {post.content}
            </div>
        </div>
    );
};

export const BlogPostList = () => {
    return (
        <div className="p-6 sm:p-10 my-10 max-w-4xl mx-auto font-sans bg-white rounded-lg shadow-lg">
            <h1 className="text-2xl sm:text-4xl mb-6 sm:mb-8 text-gray-800 text-center">Blog Posts</h1>
            <ul className="space-y-4">
                {blogPosts.map(post => (
                    <li key={post.id} className="bg-white p-4 rounded hover:bg-gray-200 transition-colors duration-200">
                        <Link to={`/blog/${post.id}`} className="text-blue-500 hover:text-blue-700 text-lg font-medium">
                            {post.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};


export default Blog;