import React, { useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import { addDoc, collection, query, where, getDocs } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { UserAuth } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup() {
  const { googleSignIn, user } = UserAuth();
  const usersRef = collection(firestore, "users");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [loading, setLoading] = useState(false); // New state to manage loading

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      await googleSignIn();
    } catch (error) {
      console.error("Google sign-in error: ", error);
      toast.error("Google sign-in failed. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }

    try {
      // Create user with email and password
      const {user} = await createUserWithEmailAndPassword(auth, email, password);

      // Store additional user information in Firebase database
      const userData = {
        user_id: user.uid,
        email: email,
      };

      await addDoc(collection(firestore, "users"), userData);

      // Redirect to home page
      navigate("/");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/weak-password") {
        toast.error("The password is too weak.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (errorCode === "auth/invalid-email") {
        toast.error("The email address is invalid.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (errorCode === "auth/email-already-in-use") {
        toast.error("The email address already in use.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  useEffect(() => {
    if (user && user.email) {
      const emailQuery = query(usersRef, where("email", "==", user.email));

      const fetchUserData = async () => {
        const emailQuerySnapshot = await getDocs(emailQuery);
        return emailQuerySnapshot.docs.map((doc) => doc.data());
      };

      fetchUserData().then((emailQueryData) => {
        const hasEthnicity = emailQueryData.some((doc) => doc.ethnicity);
        const hasGender = emailQueryData.some((doc) => doc.gender);
        const hasCountry = emailQueryData.some((doc) => doc.country);
        const hasPostcode = emailQueryData.some((doc) => doc.postcode);
        const hasDOB = emailQueryData.some((doc) => doc.date_birth);

        if (emailQueryData.length > 0 && hasEthnicity && hasGender && hasCountry && hasPostcode && hasDOB) {
          // User is complete, redirect to home page
          navigate("/active-questions");
        } else {
          // User isn't complete, redirect to complete profile page to provide additional information
          navigate("/complete-profile");
        }
      });
    }
  }, [user, navigate, usersRef]);

  return (
      <div className="container mx-auto min-h-screen">
        <div className="flex items-center justify-center">
          <div className="w-3/4 md:w-1/2">
            <h1 className="text-black text-center text-3xl font-bold my-6">
              Sign up for an account
            </h1>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <div className="bg-white rounded px-6 pt-6 pb-8 mb-4 max-w-md w-full">
            <form onSubmit={handleSignUp}>
              <div className="mb-4 flex flex-col">
                <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email:
                </label>
                <input
                    className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={user?.email ? user.email : "example@gmail.com"}
                    required
                />
              </div>
              <div className="mb-4 flex flex-col">
                <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Password:
                </label>
                <input
                    className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="******************"
                    required
                />
              </div>
              <div className="mb-4 flex flex-col">
                <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                  Confirm Password:
                </label>
                <input
                    className={`shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                        passwordMatchError ? "border-red-500" : "bg-gray-100"
                    }`}
                    id="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setPasswordMatchError(false);
                    }}
                    placeholder="******************"
                    required
                />
                {passwordMatchError && (
                    <p className="text-red-500 text-xs mt-1">Passwords do not match.</p>
                )}
              </div>

              <div className="flex items-center justify-center py-6">
                <button
                    type="button"
                    onClick={handleGoogleSignIn}
                    className="bg-black hover:bg-blue-700 text-white py-2 mx-10 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={loading} // Disable button while loading
                >
                  {loading ? "Loading..." : "Google"}
                </button>

                <button
                    className="bg-black hover:bg-blue-700 text-white py-2 mx-10 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer/>
      </div>
  );
}

export default Signup;
