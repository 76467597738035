import React from "react";
import {FaFacebookSquare, FaWhatsapp, FaTwitterSquare} from "react-icons/fa";
import {handleFacebookShare, handleWhatsAppShare, handleTwitterShare} from "./ThankfulMessage";

const Footer = () => {
    return (
        <div className="p-4 flex max-w-[1240px] mx-auto w-full justify-between text-gray-300 md:mt-0 sm:mt-12 mt-24">
            <div className="space-y-4">
                <h1 className="text-3xl font-bold text-black">101 Humans</h1>
                <p className="text-black">
                    Get answers to questions you're afraid to ask
                </p>
                <div className="flex space-x-4">
                    <FaFacebookSquare
                        size={30}
                        style={{color: "#3b5998"}}
                        type="button"
                        onClick={handleFacebookShare}
                    />
                    <FaWhatsapp
                        size={30}
                        style={{ color: "#25D366" }}
                        type="button"
                        onClick={handleWhatsAppShare}
                    />
                    <FaTwitterSquare
                        size={30}
                        style={{color: "#1DA1F2"}}
                        type="button"
                        onClick={handleTwitterShare}
                    />
                </div>
            </div>
        </div>
    );
};

export default Footer;
