import React, {useEffect} from 'react';

const AdSenseSquareAd = () => {
    useEffect(() => {
        // Load the AdSense script
        const script = document.createElement('script');
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        script.async = true;
        script.crossOrigin = "anonymous";
        script.setAttribute('data-ad-client', 'ca-pub-7021270592178699');
        document.body.appendChild(script);

        // Create and insert ad
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <div>
            <ins className="adsbygoogle"
                 style={{display: 'block'}}
                 data-ad-client="ca-pub-7021270592178699"
                 data-ad-slot="3747272659"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </div>
    );
};

export default AdSenseSquareAd;
