import {FaFacebook, FaWhatsapp, FaTwitter} from "react-icons/fa";
import {ToastContainer} from "react-toastify";
import React from "react";

// Add share button click handlers
export const handleFacebookShare = () => {
    const shareUrl = "https://101humans.com/";
    const shareText = `Check out this question on 101 Humans - ${shareUrl}`;
    window.open(
        `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
            shareUrl
        )}&quote=${encodeURIComponent(shareText)}`,
        "_blank"
    );
};

export const handleWhatsAppShare = () => {
    const shareUrl = "https://101humans.com/";
    const shareText = `Check out this question on 101 Humans - ${shareUrl}`;
    window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`,
        "_blank"
    );
};

export const handleTwitterShare = () => {
    const shareUrl = "https://101humans.com/";
    const shareText = `Check out this question on 101 Humans - ${shareUrl}`;
    window.open(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            shareUrl
        )}&text=${encodeURIComponent(shareText)}`,
        "_blank"
    );
};


const ThankfulMessage = (props) => {
    return (
        <div className="bg-white-700 text-black p-8 rounded-lg shadow-lg mx-6 md:mx-2">
            <div className="text-center">
                <h2 className="text-2xl font-bold mb-4">Thank you for your answers!</h2>
                <p>Your responses have been recorded. </p>
                <p>
                    We will contact you back with analytics once we reach 101 answers,
                    missing {101 - props.answers} answers.
                </p>
            </div>
            <ToastContainer/>
            <div
                className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4 mt-4">
                <button
                    type="button"
                    onClick={handleFacebookShare}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-12 rounded flex items-center"
                >
                    <FaFacebook className="mr-2"/> Share on Facebook
                </button>
                <button
                    type="button"
                    onClick={handleWhatsAppShare}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded flex items-center"
                >
                    <FaWhatsapp className="mr-2"/> Share on WhatsApp
                </button>
                <button
                    type="button"
                    onClick={handleTwitterShare}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-12 rounded flex items-center"
                >
                    <FaTwitter className="mr-2"/> Share on Twitter
                </button>
            </div>


        </div>
    );
};

export default ThankfulMessage;
