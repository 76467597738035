import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {})
            .catch(error => {console.error('Service worker registration failed:', error);});
    });
}
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);